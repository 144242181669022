* {
	margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
}

body {
	font-family: Roboto;
	background-color: #faf4f2;
}

button {
	border: none;
	color: #fff;
	font-family: Roboto;
	cursor: pointer;
}

input {
	border: none;
}

h1,
h2,
h3,
h4 {
	font-family: Archivo;
}

.app-component {
	height: 100%;
}

nav {
	display: flex;
	align-items: center;
	height: 100px;
	width: 100%;
	box-sizing: border-box;
	padding: 0 25px;

	.left-nav {
		display: flex;
		align-items: center;

		img {
			margin-right: 30px;
			width: 45px;
		}

		ul {
			display: flex;

			li {
				cursor: pointer;

				&:not(:last-of-type) {
					margin-right: 30px;
				}
			}
		}
	}

	.right-nav,
	.nav-mobile {
		margin-left: auto;
	}

	.right-nav {
		button {
			background-color: transparent;

			border-radius: 20px;
			font-weight: 500;

			&:nth-of-type(2) {
				border: 2px solid #ffffff;
				padding: 10px 30px;
				margin-left: 30px;
			}
		}
	}

	.nav-mobile {
		display: none;
	}
}

.hero-section {
	background-image: url('./assets/images/hero-section-background.png');
	width: 100%;
	height: 100%;
	background-size: cover;
	border-radius: 0 0 14% 14%;
	background-position: center;
	background-repeat: no-repeat;
	box-sizing: border-box;
	color: #fff;

	> div {
		height: calc(100% - 100px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;

		h1 {
			font-size: 4.6em;
			font-weight: 700;
		}

		p {
			max-width: 550px;
			margin-top: 30px;
			font-size: 1.2em;
		}

		button {
			background-color: #e76f51;
			border-radius: 25px;
			padding: 15px 50px;
			margin-top: 20px;
			font-weight: 500;
			font-size: 0.9em;
			transition: all 0.2s ease-in-out;

			&:hover {
				transform: scale(1.1);
			}
		}
	}
}

.perks-section {
	position: relative;
	bottom: 60px;

	.cards-container {
		display: grid;
		align-content: center;
		justify-items: center;
		row-gap: 30px;
		column-gap: 10px;
		grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));

		.card {
			max-width: 300px;
			height: 250px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;
			background-color: #ffffff;
			color: #000;
			padding: 20px;
			border-radius: 15px;
			box-sizing: border-box;

			img {
				width: 50px;
			}
		}
	}
}

.destinations-section {
	text-align: center;

	> h1 {
		margin-top: 10px;
		margin-bottom: 50px;
	}

	.view-all-button {
		padding: 15px 0;
		width: 180px;
		font-weight: 500;
		margin: 40px;
		z-index: 1;
		position: relative;
		font-size: inherit;
		font-family: inherit;
		background-color: #fff;
		color: #e76f51;
		border: 1px solid #e76f51;
		border-radius: 25px;
		overflow: hidden;
		transform-origin: left;

		&::before {
			content: '';
			z-index: -1;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: #e76f51;
			transform: scaleX(0);
			transform-origin: left;
			transition: transform 0.45s ease-in-out;
		}

		&:hover {
			cursor: pointer;
			color: #fff;

			&::before {
				transform-origin: left;
				transform: scaleX(1.5);
			}
		}
	}
}

.destinations-cards-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-template-rows: auto;
	align-items: center;
	justify-items: center;
	row-gap: 50px;
	max-width: 1400px;
	margin: 0 auto;

	.destinations-card {
		display: flex;
		flex-direction: column;
		background-color: #ffffff;
		width: 285px;
		max-height: 450px;
		padding: 20px 30px;
		box-sizing: border-box;
		border-radius: 10px;

		.card-image {
			width: 100%;
			height: 150px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			border-radius: 10px;
		}

		h4 {
			font-weight: 600;
			margin-top: 20px;
		}

		p {
			margin-top: 10px;
			font-size: 0.9em;
		}

		button {
			background-color: #8d8d8d2f;
			color: #8d8d8d;
			padding: 15px 30px;
			border-radius: 25px;
			font-weight: 500;
			margin-top: 15px;
			transition: all 0.2s ease-in-out;

			&:hover {
				transform: scale(1.1);
			}
		}
	}
}

.subscribe-section {
	background-color: #f8e1db;
	color: #3b3735;
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 85%;
	width: 100%;
	margin: 40px auto;
	padding: 35px 80px;
	box-sizing: border-box;
	border-radius: 10px;
	box-shadow: 5px 6px 15px #3b37350d;
	background-image: url('./assets/images/subscribe-blob-background.svg');
	background-position: 100%;
	background-repeat: no-repeat;
	background-size: cover;

	h1 {
		font-size: 2em;
	}

	p {
		max-width: 400px;
		margin-top: 10px;
		margin-bottom: 20px;
	}

	.input-container {
		position: relative;
		display: inline-block;
		width: 100%;

		.mail-icon {
			position: absolute;
			left: 15px;
			top: 15px;
		}

		.send-icon {
			width: 18px;
		}

		button {
			width: 38px;
			height: 38px;
			position: absolute;
			right: 10px;
			top: 8px;
			background-color: #e76f51;
			box-shadow: 0 0 10px 2px #e76f5166;
			padding: 10px;
			border-radius: 15px;
		}

		input {
			padding: 5px 30px;
			border-radius: 10px;
			width: 100%;
			height: 55px;
			text-indent: 20px;
			box-sizing: border-box;
		}
	}

	.image-container {
		margin-left: 25px;
		img {
			max-width: 350px;
			width: 100%;
		}
	}
}

footer {
	display: flex;
	position: relative;
	padding: 70px 130px;
	margin-top: 80px;
	box-sizing: border-box;
	background-color: #f8e1db;
	border-radius: 120px 120px 0 0;

	.up-arrow-button {
		background-color: #fff;
		box-shadow: 0 0 6px 2px #d1d1d144;
		position: absolute;
		right: 120px;
		top: -20px;
		padding: 11px 16px;
		border-radius: 15px;

		img {
			width: 14px;
			margin-top: 2px;
		}
	}

	.footer-hipcamp-info {
		margin-right: 40px;

		p {
			max-width: 520px;
			margin-top: 10px;
			margin-bottom: 25px;
		}

		h3 {
			margin-bottom: 10px;
		}

		.store-badges-container {
			img {
				cursor: pointer;
				width: 100%;
				max-width: 165px;
				&:nth-of-type(1) {
					margin-right: 10px;
				}
			}
		}
	}

	.footer-links-container {
		display: flex;
		margin-left: auto;

		h3 {
			margin-bottom: 8px;
		}

		.special-link {
			color: #41b374;
			font-weight: 500;
		}

		a {
			color: #696969;

			&:not(:last-of-type) {
				margin-bottom: 10px;
			}
		}

		> div {
			display: flex;
			flex-direction: column;

			&:not(:first-of-type) {
				margin-left: 40px;
			}
		}
	}
}

@media (max-width: 1270px) {
	.subscribe-section {
		padding: 35px 60px;
		background-position: -175px;

		h1 {
			font-size: 1.6em;
		}

		p {
			font-size: 0.95em;
		}

		.image-container {
			margin-left: 100px;

			img {
				max-width: 350px;
				width: 100%;
			}
		}
	}
}

@media (max-width: 1000px) {
	nav {
		.left-nav ul,
		.right-nav {
			display: none;
		}

		.nav-mobile {
			display: block;

			ul {
				position: absolute;
				top: 85px;
				right: 35px;
				background-color: #fff;
				color: #e76f51;
				width: 160px;
				font-size: 1.2em;
				padding: 20px;
				border-radius: 10px;
				animation: fade-in 0.2s ease-in-out;

				@keyframes fade-in {

					0% {
						opacity: 0;
						transform: translateY(2rem);
					}
				
					100% {
						opacity: 1;
						transform: translateY(0);
					}
				}

				li {
					cursor: pointer;
					&:not(:last-of-type) {
						margin-bottom: 5px;
					}
				}
			}
		}

		.hamburger-menu-button {
			display: block;
			background-color: #fff;
			padding: 14px 16px;
			box-sizing: border-box;
			border-radius: 20px;

			img {
				width: 20px;
			}
		}
	}

	.subscribe-section {
		background-image: none;

		.subscribe-section-left {
			width: 100%;
			margin: 0 auto;
		}

		.image-container {
			display: none;
		}
	}

	footer {
		flex-direction: column-reverse;
		align-items: center;
		padding: 70px 80px;

		.footer-links-container {
			margin-left: 0;
			margin-bottom: 40px;
			width: 100%;
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
			grid-template-rows: auto;
			row-gap: 40px;
			column-gap: 20px;

			> div {
				&:not(:first-of-type) {
					margin-left: 0;
				}
			}
		}

		.footer-hipcamp-info {
			margin-right: 0;
			width: 100%;
		}

		.store-badges-container {
			display: flex;
			margin-top: 15px;
		}
	}
}

@media (max-width: 600px) {
	.subscribe-section {
		background-image: none;
		.image-container img {
			display: none;
		}
	}
	footer {
		padding: 70px 40px;

		.up-arrow-button {
			right: 45%;
		}

		.store-badges-container {
			display: flex;
			flex-direction: column;
			margin-top: 15px;

			img {
				&:nth-of-type(1) {
					margin-bottom: 10px;
				}
			}
		}
	}
}
